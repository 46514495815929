export interface LocalPicklistResponse {
  data: {
    values: LocalPicklistOption[];
  };
  msg: string;
}

export interface LocalPicklistOption {
  id?: number;
  name: string;
  sf_value: string;
  active?: boolean;
  parent_id?: null;
  picklist_id?: number;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: null;
  tooltip?: string;
  children?: any[];
}

export interface PaymentMethod {
  paymentMethod: PaymentMethodEnum;
  paymentType: {
    name: PaymentTypeEnum;
    autopay: boolean;
  }[];
  ssn: boolean;
}

export enum PaymentMethodEnum {
  DEBIT = "Debit Card",
  CREDIT = "Credit Card",
  BANK = "Bank Account",
}

/**
 * Names of card type as they come from Database
 */
export enum PaymentTypeEnum {
  VISA = "Visa",
  MASTERCARD = "Master Card",
  AMERICAN_EXPRESS = "American Express",
  DISCOVER = "Discover",
  CHECKING_ACCOUNT = "Checking",
  SAVINGS_ACCOUNT = "Saving",
}

/**
 * Names of card type as they're returned from CC Library
 */
export enum CCTypeEnum {
  VISA = "visa",
  MASTERCARD = "mastercard",
  AMERICAN_EXPRESS = "amex",
  DISCOVER = "discover",
}

/**
 * Map that relates the card types from Database and Values returned from CC Library
 */
export const PaymentTypes = {
  VISA: {
    slug: CCTypeEnum.VISA,
    name: PaymentTypeEnum.VISA,
  },
  MASTERCARD: {
    slug: CCTypeEnum.MASTERCARD,
    name: PaymentTypeEnum.MASTERCARD,
  },
  AMERICAN_EXPRESS: {
    slug: CCTypeEnum.AMERICAN_EXPRESS,
    name: PaymentTypeEnum.AMERICAN_EXPRESS,
  },
  DISCOVER: {
    slug: CCTypeEnum.DISCOVER,
    name: PaymentTypeEnum.DISCOVER,
  },
  SAVINGS_ACCOUNT: {
    slug: PaymentTypeEnum.SAVINGS_ACCOUNT,
    name: PaymentTypeEnum.SAVINGS_ACCOUNT
  },
  CHECKING_ACCOUNT: {
    slug: PaymentTypeEnum.CHECKING_ACCOUNT,
    name: PaymentTypeEnum.CHECKING_ACCOUNT
  }
};
