import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Module, Role } from '@app/models';
import { MenuService } from '@app/services/menu/menu.service';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {
  filledIcons = {
    aca: 'text-accent filled',
    life: 'text-accent filled',
    medicare: 'text-accent filled',
    supplemental: 'text-accent filled',
  };

  @Input() item: Partial<Module>;

  @Input() variant: string;

  @Input() isLinkRouter = false;

  @Input() defaultOption: Partial<Role>;

  @Input() currentOption: Partial<Role>;

  @Input() isRoleItem = false;

  @Input() iconFilled = false;

  @Output() onPressItemEmit = new EventEmitter<void>();

  @Output() onPressIconEmit = new EventEmitter<void>();

  constructor(private _menuService: MenuService) {}

  getModuleIcoStatus(name: string): string {
    return this.filledIcons[name?.toLowerCase()] ?? 'text-white';
  }

  onPressItem(): void {
    this.onPressItemEmit.emit();
    this._menuService.closeMenuArcMobile();
  }

  onPressIcon(): void {
    this.onPressIconEmit.emit();
  }

  isFavorite(item): boolean {
    return this.defaultOption === item?.id;
  }
}
