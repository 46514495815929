import { AllowedFileType } from "@app/enums/allowed-file-type";
import { ByteType } from "@app/enums/byte-type";

export interface FileUpload {
  [key: string]: FileUploadConfig;
}

export interface FileUploadConfig {
  description?: string;
  icon?: string;
  allowedMegaByteMax: number;
  allowedFilesNumber?: number;
  allowedFileTypes: AllowedFileType[];
  byteType: ByteType;
  files: FileModel[];
}

export interface FileModel {
  fileUrl: string;
  fileName: string;
  fileSize?: string;
  status?: string;
  isInputFileChanged?: boolean;
}
