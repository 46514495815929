import {ComponentType, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Injectable} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {BehaviorSubject, filter, Subscription} from 'rxjs';

interface OverlayConfig {
  disableClose?: boolean;
  panelClass?: string | string[];
  backdropClass?: string | string[];
  disableBackdrop?: boolean;
  direction?: 'ltr' | 'rtl';
}

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  private _overlayRef: OverlayRef;
  private _subs: Subscription[] = [];
  private _dialogRef: MatDialogRef<any>;

  // sidepanel ref observable: experimentacion
  private sidePanelSubject: BehaviorSubject<Record<string, any>> =
    new BehaviorSubject<Record<string, any>>(null);

  public readonly sidePanel$ = this.sidePanelSubject.pipe(filter((data) => !!data));

  constructor(private _overlay: Overlay, private _dialog: MatDialog) {
  }

  openSidepanel<T>(component: ComponentType<T>, config: OverlayConfig = {}) {
    if (this._overlayRef) {
      this.close();
    }

    const panelClass = ['sidenav-panel'];

    if (config?.panelClass) {
      Array.isArray(config?.panelClass) ?
        panelClass.push(...config.panelClass) :
        panelClass.push(config.panelClass);
    }

    this._overlayRef = this._overlay.create({
      hasBackdrop: !config?.disableBackdrop,
      direction: config?.direction ?? 'ltr',
      positionStrategy: this._overlay.position().global().right().top('4rem'),
      panelClass,
      backdropClass: config?.backdropClass ?? '',
    });

    const portal = new ComponentPortal(component);
    this._overlayRef.attach(portal);

    if (!config.disableClose) {
      const sub = this._overlayRef
        .backdropClick()
        .subscribe(() => this.close());
      this._subs.push(sub);
    }
  }

  openDialog<T>(
    component: ComponentType<T>,
    config: MatDialogConfig = {
      width: '100%',
      height: '100%',
    },
    allowStack = false,
    avoidDefaultPanelClass = false
  ): MatDialogRef<T> {
    const _config = {...config};

    if (!allowStack && this._dialogRef) {
      return;
    }

    if (!avoidDefaultPanelClass) {
      const panelClass = 'cdk-dialog-panel';

      Array.isArray(_config.panelClass) ?
        _config.panelClass.push(panelClass) :
        (_config.panelClass = panelClass);
    }

    return this._dialog.open(component, _config);
  }

  setCurrentDialog(dialog) {
    this._dialogRef = dialog;
  }

  getCurrentDialog() {
    return this._dialogRef;
  }

  closeDialog() {
    this._dialog.closeAll();
  }

  close() {
    if (this._overlayRef) {
      this._overlayRef.detach();
      this._overlayRef = null;

      this._subs.forEach((sub) => sub.unsubscribe());
    }
  }
}
