import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core";
import { Status } from "@app/models";

@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
})
export class AlertComponent implements OnInit {
  message: string;
  time = 10;

  status: Status = "success";

  @Output() close: EventEmitter<any> = new EventEmitter();

  timeout: any;

  ngOnInit(): void {
    this.setCloseTimeout();
  }

  get alertClass(): string {
    return `alert ${this.status}`;
  }

  setCloseTimeout() {
    this.timeout = setTimeout(
      () => this.closeAlert(),
      this.readingTime(this.message)
    );
  }

  closeAlert() {
    this.close.emit();
  }

  readingTime(text = "") {
    const minTime = this.time * 1000;
    const numWords = text.split(" ").length;
    const duration = (numWords / 240) * 100 * 1000;

    return duration < minTime ? minTime : duration;
  }

  @HostListener("mouseenter") onMouseEnter() {
    clearTimeout(this.timeout);
  }

  @HostListener("mouseleave") onMouseLeave() {
    this.setCloseTimeout();
  }
}
