

import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
  selector: '[appTabPanelTemplate]',
})
export class TabPanelTemplateDirective {
  @Input() title: string;
  @Input() icon: string;
  constructor(public templateRef: TemplateRef<any>) {}
}

