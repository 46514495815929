import { SafeResourceUrl } from "@angular/platform-browser";

export const HEALTH_SHERPA_ROUTES = {
  signIn: "/aca/assisted-enrollment/health-sherpa",
  verify: "/aca/assisted-enrollment/health-sherpa/to-be-verified",
  verified: "/aca/assisted-enrollment",
  terms: "/aca/assisted-enrollment/terms",
  ffmIntegration: "/aca/assisted-enrollment/health-sherpa/ffm-integration",
  ffmValidation: "/aca/assisted-enrollment/health-sherpa/ffm-validation",
  ffmCredentials: "/aca/assisted-enrollment/health-sherpa/ffm-credentials",
};

export enum HEALTH_SHERPA_STATUS {
  VERIFIED = "Verified",
  FFM_EXTENSION_VALIDATION = "FFM Extension validation",
  REQUEST_ACCOUNT = "Request Account",
  TO_BE_VERIFIED = "To be verified",
  REJECTED = "Rejected",
  NOT_VERIFIED = "Not verified",
  FFM_INTEGRATION = "FFM Integration",
  FFM_INTEGRATION_VALIDATION = "FFM Integration Validation",
  FFM_NOT_VERIFIED = "FFM Not Verified",
}

/**
 * Contiene la relación status → routes para que se pueda validar en el resolver del assisted enrollment
 * por medio de un object literals
 */
export const HEALTH_SHERPA_STATUS_ROUTES = {
  [HEALTH_SHERPA_STATUS.VERIFIED]: HEALTH_SHERPA_ROUTES.verified,
  [HEALTH_SHERPA_STATUS.FFM_EXTENSION_VALIDATION]:
    HEALTH_SHERPA_ROUTES.verified,
  [HEALTH_SHERPA_STATUS.REQUEST_ACCOUNT]: HEALTH_SHERPA_ROUTES.verify,
  [HEALTH_SHERPA_STATUS.TO_BE_VERIFIED]: HEALTH_SHERPA_ROUTES.verify,
  [HEALTH_SHERPA_STATUS.REJECTED]: HEALTH_SHERPA_ROUTES.signIn,
  [HEALTH_SHERPA_STATUS.NOT_VERIFIED]: HEALTH_SHERPA_ROUTES.signIn,
  [HEALTH_SHERPA_STATUS.FFM_INTEGRATION]: HEALTH_SHERPA_ROUTES.ffmIntegration,
  [HEALTH_SHERPA_STATUS.FFM_INTEGRATION_VALIDATION]:
    HEALTH_SHERPA_ROUTES.ffmValidation,
  [HEALTH_SHERPA_STATUS.FFM_NOT_VERIFIED]: HEALTH_SHERPA_ROUTES.ffmCredentials,
};

export interface Remaining {
  attributes: RemainingAttributes;
  Id: string;
  HoursRemaining__c: number;
  FFMIntExpirationHours__c: string;
}

export interface RemainingAttributes {
  type: string;
  url: string;
}

export interface VideoFfmIntegration {
  title: string;
  linkVideo: SafeResourceUrl;
}
