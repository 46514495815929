import { ModuleAction } from "@app/enums/role";

export enum ModuleStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  MAINTENANCE = "MAINTENANCE",
}

export interface Module {
  id: number;
  name: string;
  title?: string;
  icon: string;
  route?: null | string;
  preview?: null | Record<string, any>;
  status: ModuleStatusEnum;
  order: number;
  parent_id: number | null;
  permissions: string[];
  items?: Module[];
  isPowerBi?: boolean;
  pivot?: PivotRoleOfModule;
  action?: ModuleAction;
  type?: string;
  description?: string;
}

interface ModuleResponse {
  module: Module;
}

export interface PivotRoleOfModule {
  modulable_id: number;
  module_id: number;
  modulable_type: string;
  created_at: string;
  updated_at: string;
  id: number;
  permissions: string[];
  active: number;
  order: number;
  featured?: boolean;
}

export interface ModuleDataResponse {
  data: ModuleResponse;
}
