import { Injectable } from "@angular/core";
import { HttpService } from "../http/http.service";
import { map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { LineOfBusiness } from "@app/enums/lob";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class CarrierStateLobService {
  private _module = "carrier-state-lob";

  constructor(private _http: HttpService, private _router: Router) {}

  getStatesWithLicense() {
    return this._http.getModule(`${this._module}/states-with-license`);
  }

  getLOBs(carrier_key = "") {
    return this._http
      .getModule(`${this._module}/lob?carrier_key=${carrier_key}`)
      .pipe(map(resp => resp.data?.lob ?? []));
  }

  getLineOfBusinessStates(lob: LineOfBusiness, carrier_key = "") {
    return this._http
      .getModule(`${this._module}/lob/${lob}/states?carrier_key=${carrier_key}`)
      .pipe(map(resp => resp.data?.records ?? []));
  }

  getStateCarriers(state: string, lob: LineOfBusiness, carrier_key = "") {
    if (!(state && lob)) {
      return of(null);
    }

    return this._http.getModule(
      `${this._module}/lob/${lob}/state/${state}/carriers?carrier_key=${carrier_key}`
    );
  }

  getCarrierPaymentMethods(carrier: string) {
    const methods = {
      Debit: "Debit Card",
      Credit: "Credit Card",
    };

    return this._http
      .getModule(`${this._module}/${carrier}/payment-methods`)
      .pipe(
        map(resp => {
          const paymentMethods = resp?.data?.payment_methods ?? [];

          return paymentMethods
            .map(method => ({
              paymentMethod:
                methods[method.payment_method.name] ??
                method.payment_method.name,
              paymentType: method.payment_type.name,
              autopay: method.autopay,
              ssn: method.ssn,
            }))
            .reduce((prev, curr) => {
              const index = prev.findIndex(
                m => m.paymentMethod === curr.paymentMethod
              );

              const paymentType = {
                name: curr.paymentType,
                autopay: curr.autopay,
              };

              if (index < 0) {
                prev.push({
                  paymentMethod: curr.paymentMethod,
                  paymentType: [paymentType],
                  ssn: curr.ssn,
                });
              } else {
                prev[index].paymentType.push(paymentType);
              }

              return prev;
            }, []);
        })
      );
  }

  getDentalPaymentRuledCarriers(): Observable<
    { sf_value: string; name: string }[]
  > {
    return this._http
      .getModule(`${this._module}/dental-carriers-payment?auto_payment=0`)
      .pipe(
        map(resp => {
          return resp?.data?.carriers ?? [];
        })
      );
  }

  getCarrierReconciliationMonths(carrier: string): Observable<any> {
    return this._http
      .getModule(`${this._module}/carriers-months/${carrier}`)
      .pipe(map(resp => resp?.data?.month ?? {}));
  }

  getResource(resource: string, carrier_key = ""): Observable<any> {
    return this._http
      .getModule(
        `${this._module}/resources/${resource}?carrier_key=${carrier_key}`
      )
      .pipe(map(resp => resp.data.records));
  }

  getCarrierPlans(
    carrier: string,
    state: string,
    lob: LineOfBusiness,
    county: string,
    planType?: string
  ): Observable<any> {
    let url = `${this._module}/plans?carrier=${carrier}&state=${state}&lob=${lob}&county=${county}`;
    url = planType ? `${url}&planType=${planType}` : url;
    return this._http.getModule(url).pipe(map(resp => resp.data.plans));
  }

  getStateCounties(state: string): Observable<any> {
    return this._http
      .getModule(`${this._module}/counties/${state}`)
      .pipe(map(resp => resp.data.counties));
  }

  getCslobByCarrierStateAndLob(state: string, lob: string, carrier: string) {
    return this._http
      .getModule(
        `${this._module}/get-by-carrier-and-state?lob=${lob}&carrier=${carrier}&state=${state}`
      )
      .pipe(map(resp => [resp.data.records]));
  }
}
