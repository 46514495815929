<app-header-form
  [component]="component"
  [styleMarginTop]="styleMarginTop"
  [passwordValidations]="passwordValidations"></app-header-form>

<div class="d-flex flex-column mb-5rem">
  <div
    *ngIf="accountSettings"
    class="row justify-content-center"
    [ngStyle]="{ 'margin-top.rem': styleMarginTop }">
    <cdk-form-group [ngClass]="accountSettings ? 'col-12 col-sm-6 px-2' : ''">
      <label>Current password:</label>
      <input
        [type]="visibility.current_password ? 'text' : 'password'"
        cdkInput
        autocomplete="new-password"
        [formControl]="oldPasswordControl"
        (blur)="validateCurrentPassword(oldPasswordControl?.value)"
        (keyup)="validateCurrentPassword(oldPasswordControl?.value)"
        (keydown.enter)="keyDown()"
        [placeholder]="
          accountSettings ? '***********' : 'Type your old password'
        " />
      <span
        cdkSuffix
        cdkIcon="outlined"
        styleClass="icon-input cdk-input-suffix cdk-icon-button"
        [ngClass]="{
          active: oldPasswordControl?.value,
          'error-input': inputErrors['currentPassword'],
          'cursor-pointer': oldPasswordControl?.value
        }"
        (click)="visibility.current_password = !visibility.current_password"
        >{{
          !oldPasswordControl?.value
            ? 'lock'
            : visibility.current_password
            ? 'visibility_off'
            : 'visibility'
        }}</span
      >

      <cdk-error *ngIf="oldPasswordControl?.hasError('required')"
        >This field is required</cdk-error
      >
    </cdk-form-group>
  </div>
  <div [ngClass]="accountSettings ? 'row justify-content-center' : 'd-flex flex-column'">
    <cdk-form-group
      [ngStyle]="{ 'margin-top.rem': styleMarginTop }"
      [ngClass]="accountSettings ? 'col-12 col-sm-6 ps-2' : ''">
      <label>New password:</label>
      <input
        [type]="visibility.password ? 'text' : 'password'"
        cdkInput
        autocomplete="new-password"
        [formControl]="passwordControl"
        (blur)="validatePasswordRequireKeyUp(passwordControl?.value, 'password')"
        (keyup)="
          validatePasswordRequireKeyUp(passwordControl?.value, 'password')
        "
        (keydown.enter)="keyDown()"
        [placeholder]="
          accountSettings ? '***********' : 'Type your password'
        " />
      <span
        cdkSuffix
        cdkIcon="outlined"
        styleClass="icon-input cdk-input-suffix cdk-icon-button"
        [ngClass]="{
          active: passwordControl?.value,
          'error-input': inputErrors['password'],
          'cursor-pointer': passwordControl?.value
        }"
        (click)="viewPassword()"
        >{{
          !passwordControl?.value
            ? 'lock'
            : visibility.password
            ? 'visibility_off'
            : 'visibility'
        }}</span
      >
      <cdk-error *ngIf="passwordControl?.hasError('required')"
        >This field is required</cdk-error
      >
    </cdk-form-group>
  </div>
  
  <div [ngClass]="accountSettings ? 'row justify-content-center' : 'd-flex flex-column'">
    <cdk-form-group
      [ngStyle]="{ 'margin-top.rem': styleMarginTop }"
      [ngClass]="accountSettings ? 'col-12 col-sm-6' : ''">
      <label>{{
        accountSettings ? 'Confirm new password:' : 'Confirm password:'
      }}</label>
      <input
        [type]="visibility.password_confirmation ? 'text' : 'password'"
        cdkInput
        autocomplete="new-password"
        [formControl]="passwordConfirmationControl"
        (blur)="
          validatePasswordRequireKeyUp(
            passwordConfirmationControl?.value,
            'passwordConfirm'
          )
        "
        (keyup)="
          validatePasswordRequireKeyUp(
            passwordConfirmationControl?.value,
            'passwordConfirm'
          )
        "
        (keydown.enter)="keyDown()"
        placeholder="****************" />
      <span
        cdkSuffix
        cdkIcon="outlined"
        styleClass="icon-input cdk-input-suffix cdk-icon-button"
        [ngClass]="{
          active: passwordConfirmationControl?.value,
          'error-input': inputErrors['passwordConfirm'],
          'cursor-pointer': passwordConfirmationControl?.value
        }"
        (click)="viewPasswordConfirmation()"
        >{{
          !passwordConfirmationControl?.value
            ? 'lock'
            : visibility.password_confirmation
            ? 'visibility_off'
            : 'visibility'
        }}</span
      >
      <cdk-error *ngIf="passwordConfirmationControl?.hasError('required')"
        >This field is required</cdk-error
      >
      <cdk-error *ngIf="passwordConfirmationControl?.hasError('mismatchValues')"
        >Password confirmation must be the same as password</cdk-error
      >
    </cdk-form-group>
  </div>
</div>
