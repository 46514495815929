import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateInputDirective } from './date-input.directive';
import { DataTableTemplateDirective } from './data-table/data-table-template.directive';
import { ClickStopPropagation } from './stop-propagation/stop-propagation.directive';
import { ScrollDirective } from './scroll/scroll.directive';
import { CrashImageDirective } from './crash-image/crash-image.directive';
import { DisableDirective } from './disable/disable.directive';
import { HideDirective } from './hide-directive/hide.directive';
import { TabPanelTemplateDirective } from './tab-panel/tab-panel-template.directive';

@NgModule({
  declarations: [
    DateInputDirective,
    DataTableTemplateDirective,
    ClickStopPropagation,
    ScrollDirective,
    CrashImageDirective,
    DisableDirective,
    HideDirective,
    TabPanelTemplateDirective,
  ],
  imports: [CommonModule],
  exports: [
    DateInputDirective,
    DataTableTemplateDirective,
    ClickStopPropagation,
    ScrollDirective,
    CrashImageDirective,
    DisableDirective,
    HideDirective,
    TabPanelTemplateDirective,
  ],
})
export class DirectivesModule { }
