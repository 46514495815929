import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

export interface INotificationModalData {
  filesHistory: boolean;
  title: string;
  content: string;
}

@Component({
  selector: "app-notification-modal",
  templateUrl: "./notification-modal.component.html",
  styleUrls: ["./notification-modal.component.scss"],
})
export class NotificationModalComponent {
  constructor(
    public dialog: MatDialogRef<NotificationModalComponent>,
    private _sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: INotificationModalData
  ) {}

  sanitizeInnerHtml(html: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
}
